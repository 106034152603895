import { NgModule } from '@angular/core';
import { PastOrderPricePipe } from './pipes/PastOrderPricePipe';
import { PricePipe } from './pipes/PricePipe';
import { TaxPipe } from './pipes/TaxPipe';
import { dishPricePipe } from './pipes/dishPricePipe';
import { sizePricePipe } from './pipes/sizePricePipe';
import { topingPricePipe } from './pipes/topingPricePipe';


@NgModule({
    declarations:
        [
            PastOrderPricePipe,
            PricePipe,
            TaxPipe,
            dishPricePipe,
            sizePricePipe,
            topingPricePipe
        ], exports:
        [
            PastOrderPricePipe,
            PricePipe,
            TaxPipe,
            dishPricePipe,
            sizePricePipe,
            topingPricePipe
        ],
})
export class PipeModule {
}