import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { AppServiceService } from "../../../api/app-service.service";
import { SelectOrderTypePage } from '../select-order-type/select-order-type.page';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.page.html',
  styleUrls: ['./alert.page.scss'],
})
export class AlertPage implements OnInit {
  @Input() type;
  @Input() message; // sometimes message has values that must use as language keys (ex closeNote)
  @Input() autoClose;
  @Input() title;

  alertDismissAfter = 3000;

  constructor(public modalController: ModalController, public sanitizer: DomSanitizer, public appService: AppServiceService) { }

  ngOnInit() {

    // if the autoClose true, then close alert after defined seconds 
    if (this.autoClose) {
      setTimeout(() => {
        this.modalController.dismiss();
      }, this.alertDismissAfter);
    }
  }

  async go(){
    this.modalController.dismiss();
    
    let componentProps = {
      'currentUI': 'order-type',
      'selectFavoriteRestaurant' : true
    }

    const cartModal = await this.modalController.create({
      component: SelectOrderTypePage,
      cssClass: 'custom-popup',
      componentProps: componentProps
    });

    await cartModal.present();
  }

  close(){
    this.modalController.dismiss();
  }

}
