import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tax'
})
export class TaxPipe implements PipeTransform {
  transform(price: any, currency: any, showin: any, dtype : any, type : any): any {
 
    let p = '';  
    
    if(type == 'fixed'){
      price = (Math.round(price * 100) / 100).toFixed(2);

      if(showin == 'after'){
          p = price +' '+ currency;
       }else{
        p = currency + ' '+ price;
       }  
       
    }else{
      p = price+'%';
    }

  
      return p; 
  }

  formataNumero(e: any, separador: string = '.', decimais: number = 2) {
    let a:any = e.value.split('');
    let ns:string = '';
    a.forEach((c:any) => { if (!isNaN(c)) ns = ns + c; });
    ns = parseInt(ns).toString();
    if (ns.length < (decimais+1)) { ns = ('0'.repeat(decimais+1) + ns); ns = ns.slice((decimais+1)*-1); }
    let ans = ns.split('');
    let r = '';
    for (let i=0; i < ans.length; i++) if (i == ans.length - decimais) r = r + separador + ans[i]; else r = r + ans[i];
    e.value = r;
  }

}