import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cross-selling',
  templateUrl: './cross-selling.page.html',
  styleUrls: ['./cross-selling.page.scss'],
})
export class CrossSellingPage implements OnInit {
  @Input() crossSellingProducts = [];
  @Input() selectedDishCategory;
  @Input() title = '';
  @Output() openQuestionDishPage: EventEmitter<any> = new EventEmitter();

  selectedQuestion = null;

  navigationHistory = [];
  currentUI = 'questions';

  dishes: any[] = [];
  isDishesLoading: boolean = false;

  constructor(public modalController: ModalController, public appService: AppServiceService, public translate: TranslateService) {
    // this.title = appService.selectedDish.fullDishInfo.name;
  }

  ngOnInit() {
  }

  async dismiss() {
    // console.log(this.appService.hideCartButton);
    this.appService.hideCartButton = false;
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  /**
   * Fetch products of the selected category id
   * @param category selected category
   */
  fetchProductList(category) {
    this.dishes = [];
    this.isDishesLoading = true;
    this.appService.getDishesOfTheGivenCategory(category.id).then((response: any) => {
      this.isDishesLoading = false;
      if (response.status) {
        this.dishes = response.data;
      }

      if (this.dishes.length) {
        // keep navigation history to navigate backwards
        this.navigationHistory.push('questions');
        this.currentUI = 'dishes';
        if (this.appService.isWebVersion) this.openQuestionDishPage.emit([category]);
      }

      // console.log('selectedQuestionIndex : ', this.selectedQuestion);
    }).catch((error) => {
      this.isDishesLoading = false;
      console.log('Dish error : ', error);
    });
  }

  /**
   * This will listen till the dish successfuly adding to the cart and mark the question as user responded
   * @param dish Selected dish
   */
  addToCart(dish) {
    this.appService.addToCart(dish, true).then((response: any) => {
      if (response.status === 'done' && this.selectedQuestion) {
        this.selectedQuestion.done = true;
        // display toast after adding items into cart
        this.translate.get('item_successfuly_added_to_the_cart').subscribe(
          value => {
            this.appService.showToast(value);
          }
        )
      }
    }).catch((error) => {
      console.log('response error : ', error);
    })
  }

  /**
     * This will listen till the dish successfuly adding to the cart and mark the question as user responded
     * @param dish Selected dish
     */
  openToppingPopUp(dish) {
    this.appService.topingPopupOpen(dish, true);
    let callbackResponsse = this.appService.triggerAddToCartSuccssfulyCallback().subscribe((response) => {
      if (response && response.status === 'done') {
        this.selectedQuestion.done = true;
        // display toast after adding items into cart
        this.translate.get('item_successfuly_added_to_the_cart').subscribe(
          value => {
            this.appService.showToast(value);
          }
        )
      }
      callbackResponsse.unsubscribe();
    })
  }

  /**
   * Will trigger when user click back button in web app
   */
  navigateBackInWebVersion() {
    this.currentUI = 'questions';
  }

}
